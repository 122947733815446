const {ref} = require('vue')
module.exports = {
    day: '2024-06-19 19:55:00',
    // 男方信息
    bridegroom: '曹凯',
    // 中间的消息可以是 love 也可以是 ❤️
    love: '❤️',
    // 女方信息
    bride: '闫馨月',
    // 在一起发生事件的信息
    calendarList: [
        {
            // 日期
            date: '2024-06-19',
            // 事件
            desc: '加上微信!'
        },
        {
            date: '2024-06-22',
            desc: '第一次约会 吃饭聊天,初步了解'
        },
        {
            date: '2024-06-27',
            desc: '第二次约会  吃饭聊天,算是接着了解'
        },
        {
            date: '2024-07-10',
            desc: '第三次约会 今晚月色很美'
        },
        {
            date: '2024-07-12',
            desc: '第四次约会 海底月是天上月'
        },
        {
            date: '2024-07-14',
            desc: '小插曲 送葡萄'
        },
        {
            date: '2024-07-18',
            desc: '27,很幸运遇到你'
        }
    ],
    // 照片墙的信息
    recordList: [
        {
            // 照片的地址
            src: require('@/assets/1.jpg'),
            // 照片的时间
            time: '2024-07-10',
            // 照片的描述
            desc: ''
        },
        {
            // 照片的地址
            src: require('@/assets/2.jpg'),
            // 照片的时间
            time: '2024-07-10',
            // 照片的描述
            desc: ''
        },
        {
            // 照片的地址
            src: require('@/assets/3.jpg'),
            // 照片的时间
            time: '2024-07-10',
            // 照片的描述
            desc: ''
        },
        {
            // 照片的地址
            src: require('@/assets/718-2.jpg'),
            // 照片的时间
            time: '2024-07-18',
            // 照片的描述
            desc: '♥'
        },
        {
            // 照片的地址
            src: require('@/assets/718-1.jpg'),
            // 照片的时间
            time: '2024-07-18',
            // 照片的描述
            desc: ''
        },
    ],
    // tcp备案
    websiteFiling: '',
    // tcp备案链接
    websiteFilingLink: '' ,
    // 公安备案
    policeFiling: '',
    // 公安备案链接
    policeFilingLink: ''
}
